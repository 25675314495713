import * as React from "react";
import { Container } from "react-bootstrap";
import Seo from "gatsby-plugin-wpgraphql-seo";

import { careerPageTplData } from "../data/page-tpl-career.data";
import { getCurrentPageSeo } from "../helpers/richContentHelpers";

import Layout from "../components/Layout";
import Section from "../components/Section";

import PageHead from "../components/Page/PageHead";
import Benefits from "../components/Benefits";
import OpenPositions from "../components/OpenPositions";
import NewsAndInsights from "../components/NewsAndInsights";
import GetInTouch from "../components/GetInTouch";
import ImageSectionsGroupCareer from "../components/ImageSectionsGroupCareer";
import ImageSection from "../components/ImageSection";
import ThreeColumns from "../components/ThreeColumns";
import AccordionSection from "../components/AccordionSection";

const pageCarrer = ({ pageContext: { id } }) => {
  const seoPageData = getCurrentPageSeo(id);
  const pageData = careerPageTplData(id);

  return (
    <Layout>
      {/* SEO Metas */}
      <Seo post={seoPageData} />

      {/* Page head */}
      <Container>
        <PageHead {...pageData.pageHead} />
      </Container>

      {/* Image Sections */}
      <Section className="-mobile-full">
        <ImageSectionsGroupCareer imageSections={pageData.imageSections} button={pageData.imageSectionsButton} />
      </Section>

      {/* Resources Section */}
      <Section>
        <Container>
          <Benefits {...pageData.benefits} images={pageData.images} />
        </Container>
      </Section>

      {/* Open Positions Section */}
      <Section>
        <Container>
          <OpenPositions {...pageData.openPositions} />
        </Container>
      </Section>

      <Section>
        <ImageSection imageSection={pageData.textImage.imageSection} />
      </Section>

      <Section>
        <Container>
          <ThreeColumns data={pageData.threeColumns} button={pageData.threeColumnsButton}/>
        </Container>
      </Section>

      {/* Features Section */}
      <Section>
        <AccordionSection {...pageData.features} />
      </Section>

      {/* GetInTouch Section */}
      <Section className="-mobile-full">
        <GetInTouch />
      </Section>

      {/* News and insights Section */}
      <Section>
        <NewsAndInsights />
      </Section>
    </Layout>
  );
};

export default pageCarrer;
